// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-contentful-blog-post-title-js": () => import("./../../../src/pages/blog/{ContentfulBlogPost.title}.js" /* webpackChunkName: "component---src-pages-blog-contentful-blog-post-title-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-test-map-js": () => import("./../../../src/pages/test-map.js" /* webpackChunkName: "component---src-pages-test-map-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */),
  "component---src-templates-blog-list-template-js": () => import("./../../../src/templates/blog-list.template.js" /* webpackChunkName: "component---src-templates-blog-list-template-js" */),
  "component---src-templates-blog-template-js": () => import("./../../../src/templates/blog.template.js" /* webpackChunkName: "component---src-templates-blog-template-js" */)
}

